import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action, createSelector } from "@ngxs/store";
import { ApiService } from "../services/api.service";
import { firstValueFrom } from "rxjs";
import { produce } from "immer";
import { CreateTodo, DeleteTodo, ListTodosForUpload, SetSelectedTodoId, TodosStateModel, UpdateTodo } from "./models/todos.state.model";
import { Todo, TodoTypesEnum } from "../interfaces/todo.interface";

const defaultTodoState = {
  todos: {},
  selectedTodoId: null,
};

@State<TodosStateModel>({
  name: 'todos',
  defaults: defaultTodoState
})
@Injectable()
export class TodosState {
  static get(id: string) {
    return createSelector([TodosState], (state: TodosStateModel) => {
      const todo = state.todos[id];
      if (!todo) {
        throw new Error('TodoNotFoundError');
      }
      return todo;
    });
  }

  static listByParentId(parentId: string) {
    return createSelector([TodosState], (state: TodosStateModel) => {
      const children = Object.values(state.todos).filter(todo => todo.parentId === parentId);
      return children;
    });
  }

  @Selector([TodosState])
  static todos(state: TodosStateModel): Todo[] {
    return Object.values(state.todos);
  }

  @Selector([TodosState])
  static selectedTodo(state: TodosStateModel): Todo | null {
    const todos = Object.values(state.todos);
    const selectedTodo = state.todos[state.selectedTodoId || ''];
    return {
      ...selectedTodo,
      children: todos.filter(todo => todo.parentId === selectedTodo?.id)
    }
  }

  @Selector([TodosState])
  static newestTodoId(state: TodosStateModel): string {
    const todoKeys = Object.keys(state.todos);
    const newestTodoId = todoKeys[todoKeys.length - 1];
    return state.todos[newestTodoId]?.id;
  }


  static getType(type: TodoTypesEnum) {
    return createSelector([TodosState.todos], (todos: Todo[]) => {
      return todos.filter(todo => todo.todoType === type);
    });
  }

  constructor(
    private apiService: ApiService,
  ) { }

  @Action(ListTodosForUpload)
  async listTodosForUpload(ctx: StateContext<TodosStateModel>, action: ListTodosForUpload) {
    const todos = await firstValueFrom(this.apiService.listTodosForUpload({ uploadId: action.payload.uploadId }));
    const todosMap = todos.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.id]: curr
      }
    }, {});
    ctx.patchState({ todos: todosMap });
  }

  @Action(SetSelectedTodoId)
  async setSelectedTodoId(ctx: StateContext<TodosStateModel>, action: SetSelectedTodoId) {
    ctx.patchState({ selectedTodoId: action.payload.todoId });
  }

  @Action(CreateTodo)
  async createTodo(ctx: StateContext<TodosStateModel>, action: CreateTodo) {
    const todo = await firstValueFrom(this.apiService.createTodo(action.payload.todo));
    const state = produce(ctx.getState(), draft => {
      draft.todos[todo.id] = todo;
    });
    ctx.setState(state);
  }

  @Action(UpdateTodo)
  async updateTodo(ctx: StateContext<TodosStateModel>, action: UpdateTodo) {
    const todo = await firstValueFrom(this.apiService.updateTodo(action.payload.id, action.payload.todo));
    const state = produce(ctx.getState(), draft => {
      draft.todos[todo.id] = todo;
    });
    ctx.setState(state);
  }

  @Action(DeleteTodo)
  async deleteTodo(ctx: StateContext<TodosStateModel>, action: DeleteTodo) {
    await firstValueFrom(this.apiService.deleteTodo(action.payload.uploadId, action.payload.todoId));
    const state = produce(ctx.getState(), draft => {
      delete draft.todos[action.payload.todoId];
    });
    ctx.setState(state);
  }


}
