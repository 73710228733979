import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action, createSelector } from "@ngxs/store";
import { FirstRun, GetOrganisationById, ListOrganisations, OrganisationsStateModel, UpdateOrganisation } from "./models/organisations.state.model";
import { Organisation } from "../interfaces/organisation.interface";
import { ApiService } from "../services/api.service";
import { firstValueFrom, tap } from "rxjs";
import { AuthState } from "./auth.state";
import { AuthStateModel } from "./models/auth.state.model";
import { MrKLICSubscription } from "../interfaces/subscription.interface";
import { AuthService } from "../services/auth.service";
import produce from "immer";

const defaultOrganisationsState = {
  organisations: {},
};

@State<OrganisationsStateModel>({
  name: 'organisations',
  defaults: defaultOrganisationsState
})
@Injectable()
export class OrganisationsState {
  @Selector([OrganisationsState])
  static organisations(state: OrganisationsStateModel): Organisation[] {
    return Object.values(state.organisations);
  }

  static getOrganisationById(id: string) {
    return createSelector([OrganisationsState], (state: OrganisationsStateModel) => {
      return state.organisations[id];
    });
  }

  @Selector([OrganisationsState, AuthState])
  static myOrganisation(organisationState: OrganisationsStateModel, authState: AuthStateModel): Organisation {
    return organisationState.organisations[authState.currentUser?.organisationId || ''];
  }

  @Selector([OrganisationsState.myOrganisation])
  static myActiveSubscription(myOrganisation: Organisation): MrKLICSubscription {
    return myOrganisation?.activeSubscription;
  }

  @Selector([OrganisationsState.myActiveSubscription])
  static myAvailableSlots(myActionSubscription: MrKLICSubscription): number {
    return myActionSubscription?.customAvailableSlots || myActionSubscription?.subscriptionType?.availableSlots;
  }

  @Selector([OrganisationsState, AuthState])
  static kadasterLogin(organisationState: OrganisationsStateModel, authState: AuthStateModel) {
    const organisation = organisationState.organisations[authState.currentUser?.organisationId || ''];
    if (!organisation.kadasterCustomerNumber || !organisation.kadasterUsername) {
      return null;
    }
    return {
      username: organisation.kadasterUsername,
      customerNumber: organisation.kadasterCustomerNumber,
    }
  }

  constructor(
    private apiService: ApiService,
    private authService: AuthService
  ) { }


  @Action(ListOrganisations)
  async listOrganisations(ctx: StateContext<OrganisationsStateModel>) {
    const organisations = await firstValueFrom(this.apiService.listOrganisations());
    const organisationsMap = organisations.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.id]: curr
      }
    }, {});
    ctx.patchState({ organisations: organisationsMap });
  }

  @Action(GetOrganisationById)
  async getOrganisationById(ctx: StateContext<OrganisationsStateModel>, action: GetOrganisationById) {
    const organisation = await firstValueFrom(this.apiService.getOrganisationById(action.payload.organisationId));
    const organisationsMap = {
      [organisation.id]: organisation
    };

    ctx.patchState({ organisations: organisationsMap });
  }
  
  @Action(FirstRun)
  firstRun(ctx: StateContext<OrganisationsStateModel>, action: FirstRun) {
    return this.authService.firstRun(action.payload).pipe(
      tap((organisation: Organisation) => {
        const organisationsMap = {
          [organisation.id]: organisation
        };

        ctx.patchState({
          organisations: organisationsMap
        });
      })
    )
  }

  @Action(UpdateOrganisation)
  async updateOrganisation(ctx: StateContext<OrganisationsStateModel>, action: UpdateOrganisation) {
    const fileIds = action.payload.fileIds || ctx.getState().organisations[action.payload.organisationId].files.map(file => file.id);
    const organisation = await firstValueFrom(this.apiService.updateOrganisation(action.payload.organisationId, action.payload.organisation, fileIds));
    const state = produce(ctx.getState(), draft => {
      draft.organisations[organisation.id] = organisation;
    });
    return ctx.setState(state);
  }
}
