import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action, createSelector } from "@ngxs/store";
import { ApiService } from "../services/api.service";
import { firstValueFrom } from "rxjs";
import { CreateUser, DeleteUser, ListUsers, ListUsersInOrganisation, UpdateUser, UsersStateModel } from "./models/users.state.model";
import { User } from "../interfaces/user.interface";
import { AuthState } from "./auth.state";
import { AuthStateModel } from "./models/auth.state.model";
import produce from "immer";

const defaultUsersState = {
  users: {},
  selectedUserId: null
};

@State<UsersStateModel>({
  name: 'users',
  defaults: defaultUsersState
})

@Injectable()
export class UsersState {
  @Selector([UsersState])
  static users(state: UsersStateModel): User[] {
    return Object.values(state.users);
  }

  @Selector([UsersState, AuthState])
  static me(userState: UsersStateModel, authState: AuthStateModel): User {
    return userState.users[authState.currentUser?.sub || ''];
  }


  static getUserById(userId: string) {
    return createSelector([UsersState], (state: UsersStateModel) => {
      return state.users[userId];
    });
  }

  constructor(
    private apiService: ApiService
  ) { }

  @Action(ListUsers)
  async listUsers(ctx: StateContext<UsersStateModel>) {
    const users = await firstValueFrom(this.apiService.listUsers());
    const usersMap = users.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.id]: curr
      }
    }, {});
    ctx.patchState({ users: usersMap });
  }

  @Action(ListUsersInOrganisation)
  async listUsersInOrganisation(ctx: StateContext<UsersStateModel>, action: ListUsersInOrganisation) {
    const users = await firstValueFrom(this.apiService.listUsersInOrganisation({ organisationId: action.payload.organisationId }));
    const usersMap = users.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.id]: curr
      }
    }, {});
    ctx.patchState({ users: usersMap });
  }

  @Action(CreateUser)
  async createUser(ctx: StateContext<UsersStateModel>, action: CreateUser) {
    const user = await firstValueFrom(this.apiService.createUser(action.payload.user));
    const state = produce(ctx.getState(), draft => {
      draft.users[user.id] = user;
    });
    return ctx.setState(state);
  }

  @Action(UpdateUser)
  async updateUser(ctx: StateContext<UsersStateModel>, action: UpdateUser) {
    const user = await firstValueFrom(this.apiService.updateUser(action.payload.userId, action.payload.user));
    const state = produce(ctx.getState(), draft => {
      draft.users[user.id] = user;
    });
    return ctx.setState(state);
  }

  @Action(DeleteUser)
  async deleteUser(ctx: StateContext<UsersStateModel>, action: DeleteUser) {
    await firstValueFrom(this.apiService.deleteUser(action.payload.userId));

    const state = produce(ctx.getState(), draft => {
      delete draft.users[action.payload.userId];
    });
    return ctx.setState(state);
  }

}
