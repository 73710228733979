import { Injectable } from '@angular/core';
import { SUPPORT } from 'src/app/core/constants';
import { FaqContentItem } from '../interfaces/faq.interface';


@Injectable({
  providedIn: 'root'
})
export class FAQService {
  public faqContentItems: FaqContentItem[] = [
    {
      alias: 'hoeveel-gebruikers',
      question: 'Met hoeveel medewerkers kan ik Mister KLIC gebruiken?',
      answer: `<p> Mister KLIC kent geen limiet op het aantal gebruikers. U kunt dus met al uw
                    medewerkers of onderaannemers gebruik maken van dé alles-in-1 KLIC-applicatie, zodat iedere gebruiker beschikt over de juiste KLIC-informatie.
                </p>`,
      expanded: false,
      category: 'abonnement'
    },
    {
      alias: 'KLICS-in-WMS-of-WFS',
      question: 'Hoe maak ik gebruik van de KLIC-WMS en KLIC-WFS?',
      answer: `<p>U krijgt per KLIC-melding
                    toegang tot de juiste WFS- en WMS-URLs van alle KLIC-meldingen binnen uw bedrijf. Zo kunt u een KLIC-meldingen integreren in uw eigen applicatie, 
                    maar ook in ArcGIS of QGIS. De KLIC-WMS en KLIC-WFS volgen de webstandaarden zoals die
                    gedefinieerd zijn door het Open Geospatial Consortium. </p>`,
      expanded: false,
      category: 'export'
    },
    {
      alias: 'welke-bestandsformaten-exporteren',
      question: 'In welke bestandsformaten kan ik KLIC-meldingen exporteren?',
      answer: `<p>U kunt KLIC-meldingen in de volgende bestandsformaten
                    downloaden:
                    </p>
                  <ul>
                    <li>DFX en DWG: dit bestandsformaat is handig voor het gebruik in programma's als AutoCAD en Revit. </li>
                    <li>Geopackage: een formaat dat kan worden gebruikt in GIS-programma's als QGIS en ArcGIS.</li>
                    <li>ZIP: dit betreft de oorspronkelijke KLIC-melding en is de meest complete download, die naast de KLIC-melding alle relevante metagegevens en
                      bijbehorende PDF's bevat. </li>
                    <li>PDF: Het handigste formaat om uw KLIC-melding te mailen. </li>
                  </ul>`,
      expanded: false,
      category: 'export'
    },
    {
      alias: 'verplicht-crow-500',
      question: 'Ben ik verplicht om de CROW-500 te volgen?',
      answer: `<p>De Hoge Raad heeft aangegeven dat de Richtlijn zorgvuldig graafproces (CROW-500) erg belangrijk is bij de beoordeling of iemand op een veilige manier graafwerkzaamheden heeft uitgevoerd. Volgens de Hoge Raad moet een grondroerder deze richtlijn volgen, wil de gronroerder geen risico lopen om aansprakelijk te worden gesteld voor graafschade. Dit kan alleen anders zijn als er goede redenen zijn om af te wijken van de richtlijn.
              Voor bedrijven die graafwerkzaamheden verrichten betekent dit in de praktijk dat ze zoveel mogelijk de CROW-500 moeten volgen. Bij het afwijken van de richtlijn is de kans heel groot dat u aansprakelijk wordt gesteld voor de schade die tijdens het graven wordt veroorzaakt. Door de richtlijn te volgen, wat met u met Mister KLIC aantoonbaar kunt doen, beperkt u dus een aanzienlijk aansprakelijkheidsrisico.
                    </p>`,
      expanded: false,
      category: 'inhoud'
    },
    {
      alias: 'hoeveel-kost-mr.-KLIC.',
      question: 'Wat zijn de kosten van het gebruik van Mister KLIC?',
      answer: `<p>Het Mister KLIC Start abonnement is gratis. De kosten van Mister KLIC Plus
                    bedragen
                    125 euro per maand, waarvoor u 10 KLIC-meldingen per maand kunt doen. De kosten van Mister KLIC Enterprise hangen af van het aantal KLIC-meldingen.</p>`,
      expanded: false,
      category: 'abonnement'
    },
    {
      alias: 'abbonement-looptijd',
      question: 'Wat is de looptijd van een abonnement op Mister KLIC?',
      answer: `<p>De abonnementen op Mister KLIC zijn maandelijks opzegbaar. U zit dus nooit ergens aan vast.</p>`,
      expanded: false,
      category: 'abonnement'
    },
    {
      alias: 'welke-typen-KLIC-meldingen',
      question: 'Welke typen KLIC-meldingen kan ik in Mister KLIC uploaden?',
      answer: `<p>Mister KLIC werkt zowel met graafmeldingen als oriëntatieverzoeken.<p>Er zijn drie typen KLIC-meldingen:</p>
                    <ul>
                      <li>Graafmeldingen: dit zijn de KLIC-meldingen die u nodig hebt als u daadwerkelijk wilt gaan graven. Deze
                        meldingen hebben een maximale gebiedsomvang van 500 x 500 meter en een geldigheidsduur van 20 werkdagen.
                      </li>
                      <li>Oriëntatieverzoeken: deze meldingen hebben een maximale gebiedsomvang van 2.500 x 2.500 meter en een
                        onbeperkte geldigheidsduur. Een oriëntatieverzoek biedt uitkomst als u nog niet weet wanneer u wilt gaan
                        graven, maar wel alvast wilt weten waar de kabels en leidingen op de graaflocatie liggen.</li>
                      <li>Calamiteitenmeldingen: deze KLIC-melding is verplicht als er sprake is van dreigende schade of dreigend
                        letsel op een graaflocatie, bijvoorbeeld in het geval van een gaslek. De calamiteitenmelding heeft een
                        maximale gebiedsomvang van 500 x 500 meter en bevat informatie over de netbeheerders met kabels en
                        leidingen
                        in het gebied, evenals de noodnummers van deze beheerders.</li>
                    </ul><p>Aan ondersteuning voor calamiteitenmeldingen wordt nog gewerkt.</p>`,
      expanded: false,
      category: 'inhoud'
    },
  ]
}