import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action } from "@ngxs/store";
import { MaintenanceLayers, MapMode, MapStateModel, ResetCenterAndZoom, SetCenterAndZoom, SetMapMode, UpdateLayers } from "./models/map.state.model";
import { BACKGROUND_LAYERS } from "src/app/feature/klic/klic-viewer/map-settings/basemap-layers";

const defaultMapState = {
    mapMode: MapMode.inspect,
    center: [],
    zoom: 19,
    layers: { 
        basemaps: BACKGROUND_LAYERS.map(layer => layer.id), 
        klicLayerFilter: [],
        maintenanceLayers: {
            eisVoorzorgsmaatregel: true,
            extraDetailInfo: true,
            annotations: true,
            maatvoeringen: true,
            dieptes: true,
            design: false,
            risk: false,
            todo: true,
        }
    }
};

@State<MapStateModel>({
    name: 'mapv2',
    defaults: defaultMapState
})
@Injectable()
export class MapState {
    @Selector([MapState])
    static mapMode(state: MapStateModel) {
        return state.mapMode;
    }

    @Selector([MapState])
    static center(state: MapStateModel) {
        return state.center;
    }

    @Selector([MapState])
    static zoom(state: MapStateModel) {
        return state.zoom;
    }

    @Selector([MapState])
    static layers(state: MapStateModel) {
        return state.layers;
    }

    @Selector([MapState.layers])
    static basemaps(visibleLayers: { basemaps: string[], klicLayerFilter: string[], maintenanceLayers: MaintenanceLayers }) {
        return visibleLayers.basemaps;
    }

    @Selector([MapState.layers])
    static klicLayerFilter(visibleLayers: { basemaps: string[], klicLayerFilter: string[], maintenanceLayers: MaintenanceLayers }) {
        return visibleLayers.klicLayerFilter;
    }

    @Selector([MapState.layers])
    static maintenanceLayers(visibleLayers: { basemaps: string[], klicLayerFilter: string[], maintenanceLayers: MaintenanceLayers }) {
        return visibleLayers.maintenanceLayers;
    }

    constructor() { }

    @Action(SetMapMode)
    setMapMode(ctx: StateContext<MapStateModel>, action: SetMapMode) {
        ctx.patchState({
            mapMode: action.payload.mapMode
        })
    }

    @Action(SetCenterAndZoom)
    setCenterAndZoom(ctx: StateContext<MapStateModel>, action: SetCenterAndZoom) {
        ctx.patchState({
            center: action.payload.center,
            zoom: action.payload.zoom
        })
    }

    
    @Action(UpdateLayers)
    updateLayers(ctx: StateContext<MapStateModel>, action: UpdateLayers) {
        const currentLayers = ctx.getState().layers;
        ctx.patchState({
            layers: {
                basemaps: action.payload.basemaps || currentLayers.basemaps,
                klicLayerFilter: action.payload.klicLayerFilter || currentLayers.klicLayerFilter,
                maintenanceLayers: action.payload.maintenanceLayers || currentLayers.maintenanceLayers
            }
        })
    }

    @Action(ResetCenterAndZoom)
    resetCenterAndZoom(ctx: StateContext<MapStateModel>) {
        ctx.patchState({
            center: defaultMapState.center,
            zoom: defaultMapState.zoom
        })
    }
    
}
