import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action } from "@ngxs/store";
import { ApiService } from "../services/api.service";
import { firstValueFrom } from "rxjs";
import { ClearSelection, KlicNetworkObject, KlicPrecaution, ListObjectsInUpload, NextSelection, PreviousSelection, SelectionStateModel } from "./models/selection.state.model";
import { Todo } from "../interfaces/todo.interface";

const defaultSelectionState = {
  objects: [],
  selectedIndex: 0
};

@State<SelectionStateModel>({
  name: 'selection',
  defaults: defaultSelectionState
})
@Injectable()
export class SelectionState {
  @Selector([SelectionState])
  static objects(state: SelectionStateModel) {
    return state.objects;
  }

  @Selector([SelectionState])
  static currentIndex(state: SelectionStateModel) {
    return state.selectedIndex;
  }

  @Selector([SelectionState])
  static selectedObject(state: SelectionStateModel) {
    return state.objects[state.selectedIndex];
  }

  constructor(
    private apiService: ApiService
  ) { }

  @Action(ListObjectsInUpload)
  async listObjectsInUpload(ctx: StateContext<SelectionStateModel>, action: ListObjectsInUpload) {
    const networkObjects = await firstValueFrom(this.apiService.listKlicNetworkObjects(action.payload.uploadId, action.payload));
    let objects: (KlicNetworkObject | KlicPrecaution | Todo)[] = networkObjects;

    if (action.payload.includeTodos === true) {
      const todos = await firstValueFrom(this.apiService.listTodosInArea(action.payload.uploadId, action.payload));
      objects = [...networkObjects, ...todos];
    }

    ctx.setState({
      objects: objects,
      selectedIndex: 0
    });
  }

  @Action(NextSelection)
  async nextSelection(ctx: StateContext<SelectionStateModel>) {

    const currentIndex = ctx.getState().selectedIndex;
    const currentObjects = ctx.getState().objects;

    ctx.patchState({
      selectedIndex: Math.min(currentObjects.length - 1, currentIndex + 1)
    });

  }

  @Action(PreviousSelection)
  async previousSelection(ctx: StateContext<SelectionStateModel>) {
    const currentIndex = ctx.getState().selectedIndex;
    ctx.patchState({
      selectedIndex: Math.max(0, currentIndex - 1)
    });
  }

  @Action(ClearSelection)
  clearSelection(ctx: StateContext<SelectionStateModel>) {
    ctx.setState({
      objects: [],
      selectedIndex: 0
    })
  }





}
