export interface AppStateModel {
    // mapMode: MapMode;
    klicViewMode: 'table' | 'list' | 'map'; 
    showEvNotification: boolean;
}

export class SetKlicViewMode {
    static readonly type = '[App] Set Klic View Mode'
    constructor(public payload: { klicViewMode: 'table' | 'list'| 'map' }) { }
}

export class SetShowEvNotification {
    static readonly type = '[App] Set Show EV Notification'
    constructor(public payload: { showEvNotification: boolean }) { }
}

