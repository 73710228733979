
export const VERSION = '1.4.6';

export const PROJECTIONS = {
    RD: '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.999908 +x_0=155000 +y_0=463000 +ellps=bessel +units=m +towgs84=565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812 +no_defs <>',
    WGS84: '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs'
  };
  
  export const COMPANY_LOGOS = [
    { src: 'assets/landing/webp/ciag2@2x.webp', alt: 'CIAG' },
    { src: 'assets/landing/tennet.svg', alt: 'Tennet' },
    { src: 'assets/landing/webp/unihorn@2x.webp', alt: 'Unihorn' },
    { src: 'assets/landing/webp/voorinfra@2x.webp', alt: 'VoorInfra' },
    { src: 'assets/landing/webp/noordenveld@2x.webp', alt: 'Noordenveld' }
];
