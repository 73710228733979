import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action } from "@ngxs/store";

import { AppStateModel, SetKlicViewMode, SetShowEvNotification } from "./models/app.state.model";

const defaultAppState = {
    klicViewMode: 'list' as 'list',
    showEvNotification: true
};

@State<AppStateModel>({
    name: 'app',
    defaults: defaultAppState
})
@Injectable()
export class AppState {
    @Selector([AppState])
    static klicViewMode(state: AppStateModel) {
        return state.klicViewMode;
    }

    @Selector([AppState])
    static showEvNotification(state: AppStateModel) {
        return state.showEvNotification;
    }

    constructor() { }

    @Action(SetKlicViewMode)
    setKlicViewMode(ctx: StateContext<AppStateModel>, action: SetKlicViewMode) {
        ctx.patchState({
            klicViewMode: action.payload.klicViewMode
        })
    }

    @Action(SetShowEvNotification)
    setShowEvNotification(ctx: StateContext<AppStateModel>, action: SetShowEvNotification) {
        ctx.patchState({
            showEvNotification: action.payload.showEvNotification
        })
    }
}
