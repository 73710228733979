import { Injectable } from "@angular/core";
import { State, Selector, StateContext, Action, createSelector } from "@ngxs/store";
import { ApiService } from "../services/api.service";
import { firstValueFrom } from "rxjs";
import { AssignUploadToProject, DeleteRisicogrid, DeleteUpload, UpdateUpload, ListUploadsForAdmin, ListUploadsForOrganisation, SetSelectedUploadId, UploadsStateModel } from "./models/uploads.state.model";
import { Upload } from "../interfaces/upload.interface";
import { produce } from "immer";

const defaultUploadsState = {
  uploads: {},
  selectedUploadId: null
};

@State<UploadsStateModel>({
  name: 'uploads',
  defaults: defaultUploadsState
})
@Injectable()
export class UploadsState {
  @Selector([UploadsState])
  static uploads(state: UploadsStateModel): Upload[] {
    return Object.values(state.uploads);
  }

  static uploadAlreadyExists(toBeUploadedFilename: string) {
    return createSelector([UploadsState.uploads], (state: Upload[]) => {
      const filenames = state.map(upload => upload.originalFileName);
      return filenames.includes(toBeUploadedFilename);
    });
  }

  @Selector([UploadsState])
  static selectedUpload(state: UploadsStateModel): Upload {

    if (state.selectedUploadId === null) {
      throw new Error('NoUploadSelectedError');
    }

    return state.uploads[state.selectedUploadId];
  }

  static numberOfUploadsForOrganisation(organisationId: string) {
    return createSelector([UploadsState.uploads], (uploads: Upload[]) => {
      return uploads.filter(upload => upload.organisationId === organisationId).length;
    });
  }

  constructor(
    private apiService: ApiService
  ) { }

  @Action(ListUploadsForOrganisation)
  async listUploadsForOrganisation(ctx: StateContext<UploadsStateModel>, action: ListUploadsForOrganisation) {
    const uploads = await firstValueFrom(this.apiService.listUploadsForOrganisation({ organisationId: action.payload.organisationId, params: action.payload.params }));
    const uploadsMap = Object.fromEntries(uploads.map((upload: Upload) => [upload.id, upload]));
    ctx.patchState({ uploads: uploadsMap });
  }

  @Action(ListUploadsForAdmin)
  async listUploads(ctx: StateContext<UploadsStateModel>, action: ListUploadsForAdmin) {
    let params = {}
    if (action.payload.q !== undefined) {
      params = {
        q: action.payload.q
      }
    }
    const uploads = await firstValueFrom(this.apiService.listUploadsForAdmin(params));

    const uploadsMap = Object.fromEntries(uploads.map((upload: Upload) => [upload.id, upload]));

    ctx.patchState({ uploads: uploadsMap });
  }

  @Action(DeleteUpload)
  async deleteUpload(ctx: StateContext<UploadsStateModel>, action: DeleteUpload) {
    await firstValueFrom(this.apiService.deleteUpload(action.payload.uploadId));

    const state = produce(ctx.getState(), draft => {
      delete draft.uploads[action.payload.uploadId];
    });
    ctx.setState(state);
  }

  @Action(DeleteRisicogrid)
  async deleteRisicogrid(ctx: StateContext<UploadsStateModel>, action: DeleteRisicogrid) {
    const upload = await firstValueFrom(this.apiService.deleteRiskGrid(action.payload.uploadId)) as any;

    const state = produce(ctx.getState(), draft => {
      draft.uploads[action.payload.uploadId] = upload;
    });
    ctx.setState(state);
  }


  @Action(AssignUploadToProject)
  async assignUploadToProject(ctx: StateContext<UploadsStateModel>, action: AssignUploadToProject) {
    await firstValueFrom(this.apiService.assignUploadToProject(action.payload));
    const state = produce(ctx.getState(), draft => {
      // if-statement needed for assigning/ removing inactive uploads to project. If latest filter does not match the status of assigned upload it's not in the state...
      if (draft.uploads[action.payload.uploadId]) {
        draft.uploads[action.payload.uploadId].projectId = action.payload.projectId!;
      }
    });
    ctx.setState(state);
  }


  @Action(SetSelectedUploadId)
  async setSelectedUploadId(ctx: StateContext<UploadsStateModel>, action: SetSelectedUploadId) {
    ctx.patchState({ selectedUploadId: action.payload.uploadId });
  }

  @Action(UpdateUpload)
  async editUpload(ctx: StateContext<UploadsStateModel>, action: UpdateUpload) {
    const updatedUpload = await firstValueFrom(this.apiService.updateUpload({ uploadId: action.payload.uploadId, uploadAttributes: action.payload.uploadAttributes }));
    const state = produce(ctx.getState(), draft => {
      draft.uploads[action.payload.uploadId] = updatedUpload!;
    });
    ctx.setState(state);
  }
}
