import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { CoreModule } from '../core.module';
import { Observable, filter } from 'rxjs';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import { UsersState } from '../states/users.state';
import { OrganisationsState } from '../states/organisations.state';
import  dayjs from 'dayjs';
import { CreateUser, DeleteUser, UpdateUser } from '../states/models/users.state.model';
import { CreateTodo, UpdateTodo } from '../states/models/todos.state.model';
import { NavigationEnd, Router } from '@angular/router';
import { AssignUploadToProject } from '../states/models/uploads.state.model';
declare let gtag: Function;

@Injectable({
  providedIn: CoreModule
})
export class GoogleAnalyticsService {

  constructor(private store: Store, private actions: Actions, private router: Router) {
  }

  public async enableTracking(userId$: Observable<string | undefined>) {
    if (environment.googleAnalytics.enable === false) {
      return;
    }

    this.addGoogleAnalyticsTag();
    this.addLeadinfoTag();

    gtag('config', 'G-HHXH1S6KF3', {anonymize_ip: true, send_page_view: false});

    // Track user properties
    this.store.select(UsersState.me).pipe(filter(user => user !== undefined && user !== null)).subscribe(user => {
      this.set('user_id', user.id)
      const organisation = this.store.selectSnapshot(OrganisationsState.myOrganisation);
      this.set('user_properties', { roles: user.roles.map(role => role.name) || [], organisation_name: organisation?.name, member_since: dayjs(user?.createdAt).format('YYYY-MM')})
    });

    // Track user_features
    this.actions.pipe(ofActionSuccessful(CreateUser, UpdateUser, DeleteUser)).subscribe(test => {
      this.event('manage_user');
    });
    // Track todo features
    this.actions.pipe(ofActionSuccessful(CreateTodo, UpdateTodo)).subscribe(test => {
      this.event('manage_todo');
    });

    this.actions.pipe(ofActionSuccessful(AssignUploadToProject)).subscribe(() => {
      this.event('assign_upload_to_project');
    })

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      this.event('page_view', {
        page_title: document.title,
        page_location: window.location.href
      })
    })
    

  }


  public set(propertyName: string, value: any) {
    if (environment.googleAnalytics.enable === false) {
      return;
    }
    gtag("set", propertyName, value);
  }
  public event(eventName: string, opts?: { [key: string]: any }) {
    if (environment.googleAnalytics.enable === false) {
      return;
    }
    gtag("event", eventName, {
      content_group: this.urlToContentGroup(window.location.pathname),
      ...opts,
    });
  }

  private urlToContentGroup(url: string) {
    const contentGroups = [
      { regex: /^\/klic\/(.*)\/viewer$/, value: 'KLIC viewer'},
      { regex: /^\/klic\/(.*)\/detail$/, value: 'KLIC detail'},
      { regex: /^\/klic\/(.*)\/taken$/, value: 'KLIC veilig graven'},
      { regex: /^\/klic\/(.*)\/documenten$/, value: 'KLIC documenten'},
      { regex: /^\/klic\/(.*)\/documenten$/, value: 'KLIC contacten'},
      { regex: /^\/klic\/projects/, value: 'Projecten'},
      { regex: /^\/klic$/, value: 'KLIC overview' },
      { regex: /^\/bestellen/, value: 'Bestellen' },
      { regex: /^\/klic\/upload/, value: 'Upload' },
    ];


    const contentGroup = contentGroups.find(group => url.match(group.regex) !== null)?.value || 'Niet gespecificeerd';
    return contentGroup;
  }

  private addGoogleAnalyticsTag() {
    const node = document.createElement('script');
    node.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalytics.tag}`;
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }



  public addLeadinfoTag() {
    if (environment.leafinfo.enable === false) {
      return;
    }
    //@ts-ignore
    (function (l, e, a, d, i, n, f, o) {
      //@ts-ignore
      if (!l[i]) {
        //@ts-ignore
        l.GlobalLeadinfoNamespace = l.GlobalLeadinfoNamespace || [];
        //@ts-ignore
        l.GlobalLeadinfoNamespace.push(i); l[i] = function () { (l[i].q = l[i].q || []).push(arguments) }; l[i].t = l[i].t || n;
        //@ts-ignore
        l[i].q = l[i].q || []; o = e.createElement(a); f = e.getElementsByTagName(a)[0]; o.async = 1; o.src = d; f.parentNode.insertBefore(o, f);
      }
    }(window, document, 'script', 'https://cdn.leadinfo.net/ping.js', 'leadinfo', environment.leafinfo.tag));
  }

}



