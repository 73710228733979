import { Todo } from "../../interfaces/todo.interface";

export interface TodosStateModel {
  todos: {
    [key: string]: Todo;
  },
  selectedTodoId: string | null;
}

export class ListTodosForUpload {
  static readonly type = '[Todo] ListForUpload';
  constructor(public payload: { uploadId: string }) { }
}

export class SetSelectedTodoId {
  static readonly type = '[Todo] SetSelected';
  constructor(public payload: { todoId: string }) { }
}


export class CreateTodo {
  static readonly type = '[Todo] Create';
  constructor(public payload: {
    todo: Omit<Todo, 'createdAt' | 'updatedAt'>
  }) { }
}
export class DeleteTodo {
  static readonly type = '[Todo] Delete';
  constructor(public payload: {
    todoId: string,
    uploadId: string
  }) { }
}

export class UpdateTodo {
  static readonly type = '[Todo] Update';
  constructor(public payload: {
    id: string,
    todo: Partial<Todo>
  }) { }
}

