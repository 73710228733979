import { Geometry } from "geojson";
import { Todo } from "../../interfaces/todo.interface";
import { KlicAttachment } from "../../interfaces/attachment.interface";

export interface KlicPrecaution {
    id: string;
    uploadId: string;
    gmlId: string;
    label?: any;
    omschrijving?: any;
    beginLifespanVersion: string;
    endLifespanVersion?: any;
    inNetworks: string[];
    netbeheerderNetOmschrijving: string;
    netbeheerderNetAanduiding: string;
    netbeheerderWerkAanduiding: string;
    contactEmail: string;
    contactNaam: string;
    contactTelefoon: string;
    geometry: Geometry;
    geometry_4326: Geometry;
    attachment?: KlicAttachment
}

export interface KlicNetworkObject {
    id: string;
    uploadId: string;
    gmlId: string;
    geometry: Geometry;
    geometry_4326: Geometry;
    extraGeometrie?: Geometry;
    extraGeometrie_4326?: Geometry;
    validFrom: string;
    validTo?: any;
    beginLifespanVersion: string;
    endLifespanVersion?: any;
    objectType: string;
    inNetwork: string;
    currentStatus: string;
    data: {
        [key: string]: any
    };
}

export interface SelectionStateModel {
    objects: (KlicNetworkObject | KlicPrecaution | Todo)[];
    selectedIndex: number;
}

export class ListObjectsInUpload {
    static readonly type = '[Selection] ListObjectsInUpload'
    constructor(public payload: { uploadId: string, lat: number; lng: number, buffer: number, utilityNetworks: string[], includeTodos: boolean, objectsToQuery: string[] }) { }
}

export class NextSelection {
    static readonly type = '[Selection] Next'
    constructor() { }
}

export class PreviousSelection {
    static readonly type = '[Selection] Previous'
    constructor() { }
}

export class ClearSelection {
    static readonly type = '[Selection] Clear'
    constructor() { }
}



