import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { SeoService } from 'src/app/core/services/seo.service';

@Component({
    selector: 'app-structured-data',
    template: '<div class="structured-data" [innerHTML]="html"></div>',
    styleUrls: ['./structured-data.component.scss']
})
export class StructuredDataComponent implements OnInit {
    public html!: SafeHtml;
    public scriptTag!: {
        type: string,
        text: string,
    };
    constructor(
        private renderer2: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        private seo: SeoService) { }

    ngOnInit() {
        this.scriptTag = this.renderer2.createElement('script');
        this.scriptTag.type = 'application/ld+json';

        this.seo.structuredData.subscribe(sdObject => {
            this.updateScriptTag(sdObject);
        });
    }

    private updateScriptTag(linkedData: any[]) {
        this.renderer2.removeChild(this.document.body, this.scriptTag);
        this.scriptTag.text = JSON.stringify(linkedData);
        this.renderer2.appendChild(this.document.body, this.scriptTag);
    }
}